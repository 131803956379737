import styled from 'styled-components';

import {useAppSelector} from '@store/hooks/hooks';

import {Colors} from '@components/styledComponents/base/Colors';
import { typography } from '@components/styledComponents/base/typography';

const reports = [
    {time: <>4.02.2023 UTC<br/> (3.02.2023 local time)</>, sum: '$ 4 890.91'},
    {time: <>19.01.2023 UTC<br/> (18.01.2023 local time)</>, sum: '$ 450.00'},
    {time: <>28.12.2022 UTC<br/> (27.12.2022 local time)</>, sum: '$ 1 290.50'},
    {time: <>12.12.2022 UTC<br/> (12.12.2022 local time)</>, sum: '$ 900.00'},
    {time: <>29.11.2022 UTC<br/> (28.11.2022 local time)</>, sum: '$ 12 890.49'},
];

export default function Reports () {

    const {language} = useAppSelector(state => state.generalStatesSlice);

    const title = {
        en: 'Reports',
        ru: 'Отчеты',
        uz: 'Hisobotlar',
        lt: 'Ataskaitos',
    };

    const invoiceDate = {
        en: 'Invoice date',
        ru: 'Дата выставления',
        uz: 'Hisobotlar',
        lt: 'Ataskaitos',
    };

    const amount = {
        en: 'Amount',
        ru: 'Сумма',
        uz: 'Hisobotlar',
        lt: 'Ataskaitos',
    };

    const account = {
        en: 'Account',
        ru: 'Счет',
        uz: 'Hisobotlar',
        lt: 'Ataskaitos',
    };

    const download = {
        en: 'Download',
        ru: 'Скачать',
        uz: 'Hisobotlar',
        lt: 'Ataskaitos',
    };

    return (
        <Container>
            <ThisTitle>
                {title[language]}
            </ThisTitle>
            <Header>
                <HeaderTitle>{invoiceDate[language]}</HeaderTitle>
                <HeaderTitle>{amount[language]}</HeaderTitle>
                <HeaderTitle>{account[language]}</HeaderTitle>
            </Header>
            {
                reports.map((report, index) => (
                    <Row key={index}>
                        <ReportParagraph>
                            {report.time}
                        </ReportParagraph>
                        <ReportParagraph>
                            {report.sum}
                        </ReportParagraph>
                        <DownLoadParagraph>
                            <DownLoadIcon alt='download icon' src={'/icons/account/company-account/download.svg'}/>
                            {download[language]}
                        </DownLoadParagraph>
                    </Row>

                ))
            }

        </Container>
    );
}


const Container = styled.div`
  background: #FFFFFF;
  box-shadow: 4px 4px 44px rgba(0, 0, 0, 0.05), 4px 4px 15px rgba(0, 0, 0, 0.07);
  border-radius: 10px;

  padding: 23px 0 14px 0;

  width: 100%;

  max-width: 316px;

  overflow: hidden;
  justify-self: center;
`;

const ThisTitle = styled.div`
  ${typography.default};
  font-size: 14.0444px;
  font-weight: 600;
  line-height: 130%;
  color: ${Colors.GRAY_800};

  padding: 0 25px 10px 25px;

  @media (max-width: 600px) {
    padding: 0 8px 8px 8px;
  }
`;

const Header = styled.div`
  background: #F8F9FD;
  border-radius: 5.85185px;
  padding: 8px 25px;
  
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  justify-items:  end;

  @media (max-width: 600px) {
    padding: 8px;
  }
`;

const HeaderTitle = styled.div`
  ${typography.default};
  font-size: 10px;
  line-height: 140%;
  color: ${Colors.GRAY_600};
  
  &:first-child {
    justify-self: start;
  }
`;

const Row = styled.div`
  padding: 8px 25px;

  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  justify-items:  end;
  align-items: center;

  @media (max-width: 600px) {
    padding: 8px;
  }
`;

const ReportParagraph = styled.span`
    ${typography.default};
  font-size: 10px;
  line-height: 140%;
  color: ${Colors.GRAY_800};
  &:first-child {
    justify-self: start;
  }
`;

const DownLoadIcon = styled.img`
  min-width: 9.36px;
  min-height: 9.36px;
  max-height: 9.36px;
  max-height: 9.36px;
  object-fit: contain;
  
`;

const DownLoadParagraph = styled.div`
  ${typography.default};
  font-size: 10px;
  line-height: 140%;
  color:${Colors.BLUE};
  
    display: flex;
  align-items: center;
  gap: 5px;
`;