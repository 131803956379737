import React, {useState} from 'react';
import styled, { css } from 'styled-components';

import taskCreator from '@logic/language/ex/account/common/taskCreator';
import useLanguageTexts from "@hooks/useLanguageTexts";

import {useAppSelector} from '@store/hooks/hooks';

import Button from '@components/common/buttons/Button';
import { ButtonStyle } from '@components/common/buttons/buttonStyles';
import { baseInputStyles } from '@components/common/inputs/components/baseInputStyles';
import {Colors} from '@components/styledComponents/base/Colors';
import { typography } from '@components/styledComponents/base/typography';

export default function CreateTask () {

    const {language} = useAppSelector(state => state.generalStatesSlice);

    const getCreateTaskTexts = useLanguageTexts(taskCreator);

    const title = {
        ru: 'Новая задача',
        en: 'New task',
        uz: 'Yangi vazifa',
        lt: 'Nauja užduotis',
    };

    const addFreelancer = {
        ru: 'Добавть исполнителя',
        en: 'Add freelancer',
        uz: 'Ishchini qo`shish',
        lt: 'Atlikėją pridėti',
    };
    const next = {
        ru: 'Далее',
        en: 'Next',
        uz: 'Keyingi',
        lt: 'Toliau',
    };
    const saveLikeTemplate = {
        ru: 'Сохранить как шаблон',
        en: 'Save as template',
        uz: 'Shablon sifatida saqlash',
        lt: 'Išsaugoti kaip šabloną',
    };

    const [value, setValue] = useState('');
    const [files, setFiles] = useState<Array<any>>([{id: '1', name: 'Technical specification.docx'}, {id: '2', name: 'Brief'}]);
    const [search, setSearch] = useState<string>(``);
    const [selectIsInFocus, setSelectIsInFocus] = useState<boolean>(false);
    const [selectedFreelancer, setSelectedFreelancer] = useState<Array<{email: string, role: string, id: number}>>([{email: 'fladsshro@gmail.com', role: 'personal', id: 1}]);


    function getBase64 (file: File, id: string) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setFiles(prev => [...prev, {
                id: id,
                name: file.name,
            }]);
        };
    }

    const onDropHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const newFiles = e.target!.files!;
        for(let i = 0; i < newFiles.length; i++) {
            getBase64(newFiles[i], (new Date().getTime() + i).toString());
        }
    };
    const removeFile = (id: string) => {
        setFiles(prev => prev.filter(file => file.id !== id));
    };

    return (
        <Container>
            <ThisTitle>
                {title[language]}
            </ThisTitle>
            <CreateTaskBody>
                <TextArea
                    value={value}
                    placeholder={getCreateTaskTexts("projectDescription")}
                    onChange={(e) => setValue(e.target.value)}
                />

                <Files>
                    {files.map((file) => (
                        <File
                            key={file.id}
                        >
                            <Word/>
                            <FileName>
                                {file.name}
                            </FileName>
                            <RemoveIcon onClick={()=>removeFile(file.id)}/>
                        </File>
                    ))}

                    <FileInput >
                        <PaperClip />
                        <input type="file" name="file" multiple={true} onChange={(e) => onDropHandler(e)} />
                        <LinkText>{getCreateTaskTexts("uploadFile")}</LinkText>
                    </FileInput>
                </Files>
            </CreateTaskBody>
            <Freelancers>
                <FreelancerTitle>
                    {addFreelancer[language]}
                </FreelancerTitle>

                <SelectContainer>
                    <Select
                        disabled={selectedFreelancer.length > 2}
                        onFocus={()=>setSelectIsInFocus(true)}
                        onBlur={()=>{
                            setTimeout(() => {
                                setSelectIsInFocus(false);
                            }, 100);
                        }}
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                    />
                    {/*<FreelancerList isOpen={selectIsInFocus}>*/}
                    {/*    {*/}
                    {/*        Helper.isValidEmail(search) && (*/}
                    {/*            <FreelancerEl onClick={()=>{*/}
                    {/*                setSelectedFreelancer(prev => [...prev, {email: search, role: 'personal', id: new Date().getUTCMilliseconds()}]);*/}
                    {/*            }}>*/}
                    {/*                <FreelancerElName>*/}
                    {/*                    {search}*/}
                    {/*                </FreelancerElName>*/}
                    {/*                <div className={`freelancerItem__state`}>*/}
                    {/*                    <FreelancerNew>{tasks.newFrelancer[language]}</FreelancerNew>*/}
                    {/*                    <FreelancerNew>{tasks.personalRole[language]}</FreelancerNew>*/}
                    {/*                </div>*/}
                    {/*            </FreelancerEl>*/}
                    {/*        )*/}
                    {/*    }*/}
                    {/*    {*/}
                    {/*        !Helper.isValidEmail(search) && (*/}
                    {/*            <Text font='body'>*/}
                    {/*                Ничего не найдено*/}
                    {/*            </Text>*/}
                    {/*        )*/}
                    {/*    }*/}
                    {/*</FreelancerList>*/}
                </SelectContainer>
                <SelectedFreelancers>
                    {
                        selectedFreelancer.map((freelancer) => (
                            <SelectedFreelancer key={freelancer.id}>
                                <SelectedFreelancerSide>
                                    <Text>{freelancer.email}</Text>
                                    <LinkText>{freelancer.role}</LinkText>
                                </SelectedFreelancerSide>
                                <RemoveIcon onClick={()=>setSelectedFreelancer(prev => prev.filter(f => f.id !== freelancer.id))}/>
                            </SelectedFreelancer>
                        ))
                    }
                </SelectedFreelancers>
            </Freelancers>
            <Buttons>
                <Button
                    isWide
                    styles={smallBtnsStyles}
                ><ButtonText>{next[language]}</ButtonText></Button>
                <Button
                    isWide
                    styles={smallBtnsStyles}
                    styleType={ButtonStyle.STROKE_WHITE}
                ><ButtonText>{saveLikeTemplate[language]}</ButtonText></Button>
            </Buttons>
        </Container>
    );
}

const smallBtnsStyles = css`
  min-width: unset !important;
`;

const Container = styled.div`
  height: 320px;
  max-width: 316px;
  padding: 20px;
  justify-self: center;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 4px 4px 44px rgba(0, 0, 0, 0.05), 4px 4px 15px rgba(0, 0, 0, 0.07);
  border-radius: 10px; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 14px;

  @media (max-width: 1200px) {
    padding: 23px;
    gap: 8px;
  }
`;

const ThisTitle = styled.div`
  ${typography.default};
  font-size: 14.0444px;
  font-weight: 600;
  line-height: 130%;
  color: ${Colors.GRAY_800};
  

  @media (max-width: 600px) {
    padding: 0 8px 8px 8px;
  }
`;

const TextArea = styled.textarea`
  ${typography.default};
  font-size: 8px;
  border-radius: 10px;
  border: 1px solid #d7d8de;
  width: 100%;
  resize: none;
  grid-area: 2 / 1 / 2 / 3;
  
  height: 80px;
  
  outline: none;
  
  padding: 15px;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  
  &::-webkit-input-placeholder {
    ${typography.default};
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 140%;
  }
  
  &:focus {
    outline: none;
    border: 1px solid ${Colors.GRAY_600};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${Colors.GRAY_400};
    background-position: center;
    background-repeat: no-repeat;
    height: 5px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  @media (max-width: 680px) {
    grid-area: unset;
  }
`;

const CreateTaskBody = styled.div`
    display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Buttons = styled.div`
  height: 23px;
    display: flex;
  gap: 5px;
`;


const ButtonText = styled.div`
  ${typography.default};
  line-height: 100%;
  font-size: 8px;
`;

const LinkText = styled.div`
  ${typography.default};
  line-height: 100%;
  font-size: 8px;
  color: ${Colors.BLUE};
`;

const Text = styled.div`
  ${typography.default};
  line-height: 100%;
  font-size: 8px;
  color: ${Colors.GRAY_800};
`;

const Files = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 80px);
  grid-template-rows: repeat(auto-fit, 20px);
  justify-items: start;
  grid-gap: 8px;
`;

const File = styled.div`
  width: 100%;
  position: relative;
  @extend %paragraph;
  font-size: 8px;
  line-height: 140%;
  color: ${Colors.GRAY_800};
  background: ${Colors.GRAY_100};
  border-radius: 5.85185px;
  padding: 2px 5px;
  display: grid;
  grid-template-columns: 14px auto 8px;
  grid-gap: 5px;
  align-items: center;
`;

const Word = styled.span`
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 14px;
  min-height: 14px;
  background-image: url("/icons/account/company-account/contracts/docks.svg");
`;
const RemoveIcon = styled.span`
  background-image: url("/icons/account/company-account/createTask/deleteFile.svg");
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 20px;
  min-width: 8px;
  min-height: 8px;
`;

const FileName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 8px;
`;

const PaperClip = styled.span`
  background: url("/icons/account/company-account/createTask/paperClip.svg") center
  center no-repeat;
  height: 10px;
  width: 10px;
  background-size: contain;
`;

const FileInput = styled.label`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
  }
`;

const Freelancers = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const FreelancerTitle = styled.span`
  font-weight: 600;
  font-size: 8px;
  line-height: 140%;
  color: ${Colors.GRAY_800};
`;


const SelectContainer = styled.div`
  position: relative;
  height: 20px;
  display: flex;
  &:after{
    content: '';
    margin: auto;
    position: absolute;
    background-image: url('/icons/baseUI/search/search.svg');
    background-size: contain;
    width: 10px;
    height: 10px;
    right: 15px;
    top: 0;
    bottom: 0;
  }

`;

const Select = styled.input`
  ${baseInputStyles};
  border-radius: 5px;
  font-size: 8px;
  height: 20px;
`;

// const FreelancerList = styled.div<{isOpen: boolean}>`
//   z-index: 1;
  
//   position: absolute;
  
//   transition: top .5s, opacity .5s, visibility .5s;
//   top: ${({isOpen}) => isOpen ? 'calc(100% + 5px)' : '100%'};
//   opacity: ${({isOpen}) => isOpen ? '1' : '0'};
//   pointer-events: ${({isOpen}) => isOpen ? 'unset' : 'none'};
//   visibility: ${({isOpen}) => isOpen ? 'visible' : 'hidden'};
//   box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.15);
  
//   background: white;
//   border-radius: 5px;
//   padding: 15px;

//   width: 100%;
  
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 5px;
// `;

// const FreelancerEl = styled.span`
//   display: flex;
//   width: 100%;
//   justify-content: space-between;
//   align-items: center;
//   cursor: pointer;
// `;

// const FreelancerElName = styled.span`
//     ${typography.default};
//     font-size: 8px;
// `;

const SelectedFreelancer = styled.span`
  width: max-content;
  max-width: 100px;
  height: 25.85px;

  background: #F1F4F4;
  border-radius: 4.68148px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2.92593px 5.85185px;
  gap: 14.04px;
`;

const SelectedFreelancerSide = styled.span`
    display: flex;
  flex-direction: column; 
  gap: 1px;
  overflow: hidden;
`;

const SelectedFreelancers = styled.div`
  width: 100%;
    display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;