import styled, { css } from 'styled-components';

import mainPage from '@logic/language/ex/landing/index/mainPage';
import useLanguage from '@hooks/useLanguage';

import {useAppSelector} from '@store/hooks/hooks';

import { Heading1, Title2 } from '@components/common/texts/typography/heading';
import { Text } from '@components/common/texts/typography/Typography';
import {Colors} from '@components/styledComponents/base/Colors';

import Charts from '@landing/pages/index/forWhoCards/charts/Charts';
import CreateTask from '@landing/pages/index/forWhoCards/createTask/CreateTask';

import Reports from './forWhoCards/reports/Reports';

export default function ForWho () {
    const texts = useLanguage(mainPage);
    const {language} = useAppSelector(state => state.generalStatesSlice);

    return (
        <Container>
            <Content>
                <Title2>
                    {texts.whoNeedsPayAssistant[language]}
                </Title2>

                <Cards>
                    <Card>
                        <CardTextBlock>
                            <Heading1 as='p'>
                                {texts.whoNeedsPayAssistantCEO[language]}
                            </Heading1>
                            <Text 
                                as='p'
                                font='body' 
                                color={Colors.GRAY_500}
                                styles={underTitle}>
                                {texts.whoNeedsPayAssistantCEOText[language]}
                            </Text>
                        </CardTextBlock>
                        <Charts/>
                        <Text 
                            as='p'
                            font='body' 
                            color={Colors.GRAY_900}
                            styles={cardParagraph}>
                            {texts.whoNeedsPayAssistantCEOText[language]}
                        </Text>
                    </Card>
                    <Card>
                        <CardTextBlock>
                            <Heading1 as='p'>
                                {texts.whoNeedsPayAssistantAccountants[language]}
                            </Heading1>
                            <Text 
                                as='p'
                                font='body' 
                                color={Colors.GRAY_500}
                                styles={underTitle}>
                                {texts.whoNeedsPayAssistantAccountantsText[language]}
                            </Text>
                        </CardTextBlock>
                        <Reports/>
                        <Text 
                            as='p'
                            font='body' 
                            color={Colors.GRAY_900}
                            styles={cardParagraph}>
                            {texts.whoNeedsPayAssistantAccountantsText[language]}
                        </Text>
                    </Card>
                    <Card>
                        <CardTextBlock>
                            <Heading1 as='p'>
                                {texts.whoNeedsPayAssistantPM[language]}
                            </Heading1>
                            <Text 
                                as='p'
                                font='body' 
                                color={Colors.GRAY_500}
                                styles={underTitle}>
                                {texts.whoNeedsPayAssistantPMText[language]}
                            </Text>
                        </CardTextBlock>
                        <CreateTask/>
                        <Text                           
                            as='p'
                            font='body' 
                            color={Colors.GRAY_900}
                            styles={cardParagraph}>
                            {texts.whoNeedsPayAssistantPMText[language]}
                        </Text>
                    </Card>

                </Cards>
            </Content>
        </Container>
    );
}


const Container = styled.div`
  width: 100%;
  padding: 60px 0;
`;

const Content = styled.div`
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
`;

const Cards = styled.div`
  display: flex;
  gap: 20px;
  
  @media (max-width: 1200px) {
    gap: 10px;
  }
  
  @media (max-width: 1000px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  
`;

const Card = styled.div`
  display: grid;
  grid-template-rows: 60px 320px 110px;
  justify-content: center;
  gap: 24px;
  padding: 32px;
  max-width: 380px;
  width: 100%;

  background: ${Colors.BG_LIGHT_BLUE};
  border-radius: 20px;

  @media (max-width: 1200px) and (min-width: 1000px) {
    width: 100%;
    padding: 16px;
  }
  
  @media (max-width: 1000px) and (min-width: 600px) {
    width: 100%;
    max-width: unset;    
    grid-template-columns: 1fr 316px;
    grid-template-rows: unset;
    padding: 32px;
  }
  
  @media (max-width: 600px) {
    padding: 16px;
  }

`;

const CardTextBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px
`;

const underTitle = css`
  display: none;
  
  @media (max-width: 1000px)  and (min-width: 600px){
    display: block;
  }
`;

const cardParagraph = css`
  opacity: 0.6;

  @media (max-width: 1000px) and (min-width: 600px) {
    display: none;
  }
`;