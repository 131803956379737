import React from 'react';
import styled, { css } from 'styled-components';

import RuleMessage from '@logic/forms/validators/rules/RuleMessage';
import { LandingContext } from '@context/LandingContext';

import { useAppSelector } from '@store/hooks/hooks';

import Button from '@components/common/buttons/Button';
import { Heading2, Title2 } from '@components/common/texts/typography/heading';
import { Text } from '@components/common/texts/typography/Typography';
import { Breakpoints } from '@components/styledComponents/base/breakpoints';
import { Colors } from '@components/styledComponents/base/Colors';
import { hover, mediaBreakpointDown } from '@components/styledComponents/base/functions';

interface FAQ {
    title: RuleMessage;
    answer: RuleMessage;
}

interface FAQProps {
    faqs: Array<FAQ>;
    button?: RuleMessage;
    title?: RuleMessage;
    applicationTitle?: RuleMessage;
}

export default function FAQPanel ({ faqs, button, title, applicationTitle }: FAQProps) {

    const { language } = useAppSelector(state => state.generalStatesSlice);
    const { setApplicationFormOptions } = React.useContext(LandingContext);

    return (
        <Content>
            {title &&
                <Title2>
                    {title[language]}
                </Title2>}

            <Faqs>
                {
                    faqs.map((faq, index) => (
                        <Faq key={index} onClick={(e) => {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                            if (e.target instanceof HTMLElement && e?.target?.tagName! !== 'INPUT') {
                                (e?.target as HTMLElement)?.closest('div')?.querySelector('input')?.click();
                            }
                        }
                        }>
                            <Heading2>
                                {faq.title[language]}
                            </Heading2>
                            <CheckButton
                                type={`checkbox`}
                            />
                            <Answer>
                                <Text font='body' as='p' color={Colors.GRAY_600} styles={answerText}>
                                    {faq.answer[language]}
                                </Text>
                            </Answer>
                        </Faq>
                    ))
                }
            </Faqs>

            {(button && applicationTitle) &&
                <Button
                    data-test-id='faqApplicationButton'
                    wideOnBreakPoint={680}
                    onClick={() => setApplicationFormOptions({ isOpen: true, title: applicationTitle })}>
                    {button[language]}
                </Button>}

        </Content>
    );
}

const Content = styled.div`
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  gap: 32px;

  ${mediaBreakpointDown(Breakpoints.Large)} {
        gap: 28px;
    }

  ${mediaBreakpointDown(Breakpoints.xMobile)} {
        gap: 24px;
    }
`;

const Faqs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Faq = styled.div`
  height: auto;
  position: relative;
  background: ${Colors.BG_LIGHT_BLUE};
  border-radius: 10px;
  margin-bottom: 8px;
  padding: 24px 80px 24px 32px;
  max-width: 780px;
  cursor: pointer;
`;

const CheckButton = styled.input`
  border: none;
  transition: 0.5s;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  position: absolute;
  right: 32px;
  margin: auto;
  top: 0px;
  bottom: 0;
  cursor: pointer;
  height: 34px;
  background: white;
  width: 34px;
  border-radius: 50%;

  &:before {
    position: absolute;
    content: "";
    background: url("/landing/main/FAQ/faqOpen.svg") no-repeat;
    height: 50%;
    width: 50%;
    margin: auto;
    background-size: cover;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: 0.5s;
  }

  ${hover(css`
      &:before {
      background: url("/landing/main/FAQ/croos_green.svg") no-repeat;
      height: 50%;
      width: 50%;
      background-size: cover;
    }
  `)}
  
  &:checked + div{
    max-height: 300px;
    -webkit-transition: max-height 1s linear;
    -moz-transition: max-height 1s linear;
    -ms-transition: max-height 1s linear;
    -o-transition: max-height 1s linear;
    transition: max-height 1s linear;
  }
  
  &:checked {
    &:before {
      transform: rotate(45deg);
    }
  }
`;


const Answer = styled.div`
  -webkit-transition: max-height 0.8s ease-out;
  -moz-transition: max-height 0.8s ease-out;
  -ms-transition: max-height 0.8s ease-out;
  -o-transition: max-height 0.8s ease-out;
  transition: max-height 0.8s ease-out;
  overflow: hidden;
  max-height: 0;
  color: ${Colors.GRAY_600};
`;

const answerText = css`
  width: 100%;
  max-width: 600px;
  padding-right: 40px;
  padding-top: 10px;
`;