import React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { Colors } from '@components/styledComponents/base/Colors';
import { typography } from '@components/styledComponents/base/typography';

import PayAss from '@public/icons/payassistant_logo.svg';

interface Props {
    time: string;
    text: string;
    styles?: FlattenSimpleInterpolation;
}

export const Badge = ({time, text, styles}: Props) => (
    <BagdeWrapper styles={styles}>
        <BagdeTitle>
            <BagdeName>
                <PayAss/>
                Pay Assistant</BagdeName>  
            <BagdeText>{time}</BagdeText>
        </BagdeTitle>
        <BagdeText color={Colors.PEN_BLUE}>{text}</BagdeText>
    </BagdeWrapper>
);

const BagdeWrapper = styled.div<{styles?: FlattenSimpleInterpolation}>`
    width: 300px;
    padding: 18px;
    border-radius: 17px;
    border: 1px solid white;
    background: linear-gradient(101deg, rgba(255, 255, 255, 0.83) -12.21%, rgba(255, 255, 255, 0.00) 130.35%);
    backdrop-filter: blur(17px);

    @media  screen and (max-width: 1200px) {
        width: 250px;
        padding: 14px;
    }

    @media  screen and (max-width: 1024px) {
        width: 180px;
        padding: 11px;
    }

    @media  screen and (max-width: 768px) {
        width: 160px;
    }

    ${({styles}) => styles};
`;

const BagdeTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 11px;
`;


const BagdeName = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    color: ${Colors.PEN_BLUE};
    ${typography.golosMedium};
    font-size: 18px;

    @media  screen and (max-width: 1200px) {
        font-size: 13px;
    }

    @media  screen and (max-width: 1024px) {
        font-size: 11px;
    }
`;

const BagdeText = styled.div`
    color: ${Colors.PEN_BLUE};
    ${typography.default};
    font-size: 18px;

    @media  screen and (max-width: 1200px) {
        font-size: 13px;
    }

    @media  screen and (max-width: 1024px) {
        font-size: 11px;
    }
`;


