

const mainPage = {
    mainTitle: {
        ru: 'Удобный сервис для работы с фрилансерами',
        en: 'Convenient service for working with freelancers',
        uz: 'Frilanserlar bilan ishlash uchun qulay xizmat turi',
        lt: ''
    },
    mainDescription: {
        ru: 'Массовые и быстрые выплаты по всему миру, простой документооборот, удобная интеграция по API, поддержка 24/7',
        en: 'Fast mass vendor payments around the world, simple workflow, convenient API integration and 24/7 support',
        uz: 'Butun dunyo bo\'ylab ommaviy va tezkor to\'lovlar, soddalashtirilgan hujjatlar aylanishi, API bo’yicha  qulay integratsiya,  24/7  davomida qo\'llab-quvvatlash',
        lt: ''
    },
    applicationTitle: {
        ru: 'Задайте свой вопрос',
        en: 'Ask a question',
        uz: 'Savolingizni so\'rang',
        lt: ''
    },
    applicationButton: {
        ru: 'Оставить заявку',
        en: 'Try free now',
        uz: 'Arizani qoldirish ',
        lt: ''
    },
    payAssistantIs: {
        ru: 'Pay Assistant — это:',
        en: 'Pay Assistant:',
        uz: 'Pay Assistant — bu:',
        lt: ''
    },
    payAssistantIsSafe: {
        ru: 'Безопасность',
        en: 'Safe',
        uz: 'Xavfsizlik',
        lt: ''
    },
    payAssistantIsSafeText: {
        ru: 'Закрывайте все правовые и налоговые риски при работе с фрилансерами, заключайте “безопасные сделки”',
        en: 'Minimise civil and tax risks when working with freelancers and ensure safe transactions',
        uz: 'Frilanserlar bilan ishlashda barcha qonuniy va soliq xavf-xatarlarini yopib qo’ying, “xavfsiz bitimlar” tuzing ',
        lt: ''
    },
    payAssistantIsSimple: {
        ru: 'Простота',
        en: 'Simple',
        uz: 'Oddiylik',
        lt: ''
    },
    payAssistantIsSimpleText: {
        ru: 'Заключайте один договор для всех исполнителей',
        en: 'Cooperate with professionals under a single contract',
        uz: 'Barcha pudratchilar – ijrochilar uchun bitta shartnoma tuzing',
        lt: ''
    },
    payAssistantIsConvenient: {
        ru: 'Удобство',
        en: 'Convenient',
        uz: 'Qulaylik',
        lt: ''
    },
    payAssistantIsConvenientText: {
        ru: 'Получайте документацию по каждому проекту одним пакетом и быстро производите любое количество выплат одним кликом',
        en: 'Receive documentation for each project in one package and quickly make any number of payments with one click',
        uz: 'Har bir loyiha bo’yicha hujjatlarni bitta paketda oling va bir marta klik bosish orqali har qanday miqdordagi toʻlovlarni tezda amalga oshiring',
        lt: '',
    },
    payAssistantIsModernized: {
        ru: 'Оптимизация',
        en: 'Modernized',
        uz: 'Optimallash',
        lt: ''
    },
    payAssistantIsModernizedText: {
        ru: 'Делайте выплаты исполнителям прямо из своей системы через простую интеграцию по API',
        en: 'Streamline vendor payments to contractors directly from your system through simple API integration',
        uz: 'API bo’yicha oddiy integratsiya orqali to\'g\'ridan-to\'g\'ri o’z tizimingiz orqali ijrochilarga to\'lovlarni amalga oshiring ',
        lt: ''
    },
    whoNeedsPayAssistant: {
        ru: 'Кому нужен Pay Assistant',
        en: 'Who needs Pay Assistant',
        uz: 'Pay Assistant  kimga  kerak',
        lt: ''
    },
    whoNeedsPayAssistantCEO: {
        ru: 'Владельцам бизнеса и руководителям компаний\n',
        en: 'CEOs and Company Executives',
        uz: 'Biznes  egalari va kompaniya rahbarlariga',
        lt: ''
    },
    whoNeedsPayAssistantCEOText: {
        ru: 'Оптимизируйте процессы и сократите расходы, чтобы открыть новые возможности для развития бизнеса',
        en: 'Streamline task management and cut down expenses in order to open up new business opportunities',
        uz: 'Biznesni rivojlantirish uchun yangi imkoniyatlarni ochish uchun jarayonlarni optimallashtiring va xarajatlarni qisqartiring',
        lt: ''
    },
    whoNeedsPayAssistantAccountants: {
        ru: 'Финансовым директорам и бухгалтерам',
        en: 'Financial Directors and Accountants',
        uz: 'Moliyaviy direktor va buxgalterlarga ',
        lt: ''
    },
    whoNeedsPayAssistantAccountantsText: {
        ru: 'Сэкономьте время на работу с документами и выплаты фрилансерам',
        en: 'Optimise your time management when working with documents and streamline vendor payments',
        uz: 'Hujjatlar bilan ishlash va frilanserlarga to\'lovlarni amalga oshirish uchun vaqtni tejang ',
        lt: ''
    },
    whoNeedsPayAssistantPM: {
        ru: 'Менеджерам проектов и руководителям отделов',
        en: 'Project Managers and Team Leaders',
        uz: 'Loyiha boshqaruvchilari va bo’lim rahbarlariga ',
        lt: ''
    },
    whoNeedsPayAssistantPMText: {
        ru: 'Избавьтесь от рутины — оформляйте документы и делайте выплаты одним кликом',
        en: 'Ditch the routine — draw up documents and make payments with one click',
        uz: 'Qoloqlikdan xalos bo\'ling – hujjatlarni rasmiylashtiring va bir marta klik bosish orqali to\'lovlarni amalga oshiring ',
        lt: ''
    },
    processesTitle: {
        ru: 'Настраиваем процессы для разных отраслей',
        en: 'Customising processes for different industries',
        uz: 'Biz turli sohalar uchun jarayonlarni o\'rnatdik ',
        lt: ''
    },
    CPA: {
        ru: 'CPA-сети',
        en: 'CPA networks',
        uz: 'СPA–tarmoqlar',
        lt: ''
    },
    IT: {
        ru: 'IT-разработка',
        en: 'IT development',
        uz: 'IT ishlab chiqish ',
        lt: ''
    },
    digital: {
        ru: 'Digital',
        en: 'Digital',
        uz: 'Digital',
        lt: ''
    },
    media: {
        ru: 'СМИ',
        en: 'Media',
        uz: 'OAV – Ommaviy axborot vositalari ',
        lt: ''
    },
    logistics: {
        ru: 'Логистика',
        en: 'Logistics',
        uz: 'Logistika',
        lt: ''
    },
    onlineEducation: {
        ru: 'Онлайн образование',
        en: 'Online education',
        uz: 'Onlayn-ta’lim ',
        lt: ''
    },
    publishingHouses: {
        ru: 'Издательские дома',
        en: 'Publishing houses',
        uz: 'Nashriyot uylari',
        lt: ''
    },
    callCenters: {
        ru: 'Колл-центры',
        en: 'Call centres',
        uz: 'Koll-markazlar',
        lt: ''
    },
    replenishment: {
        ru: 'Пополнение',
        en: 'Replenishment',
        uz: 'To\'ldirish',
        lt: ''
    }
};


export default mainPage;