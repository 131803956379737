import com from '@logic/language/com/landing/index/mainPage';
import ru from '@logic/language/ru/landing/index/mainPage';
import uz from '@logic/language/uz/landing/index/mainPage';


export default {
    ru,
    com,
    uz,
};

