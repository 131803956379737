

const mainPage = {
    mainTitle: {
        ru: 'Сервис массовых выплат сотрудникам на карты за рубеж и РФ',
        en: 'Convenient service for working with freelancers',
        uz: 'Frilanserlar bilan ishlash uchun qulay xizmat turi',
        lt: ''
    },
    mainDescription: {
        ru: 'Упрощаем документооборот и расчеты с удаленными исполнителями по всему миру',
        en: 'Fast mass vendor payments around the world, simple workflow, convenient API integration and 24/7 support',
        uz: 'Butun dunyo bo\'ylab ommaviy va tezkor to\'lovlar, soddalashtirilgan hujjatlar aylanishi, API bo’yicha  qulay integratsiya,  24/7  davomida qo\'llab-quvvatlash',
        lt: ''
    },
    applicationTitle: {
        ru: 'Задайте свой вопрос',
        en: 'Ask a question',
        uz: 'Savolingizni so\'rang',
        lt: ''
    },
    applicationButton: {
        ru: 'Попробовать сейчас',
        en: 'Try free now',
        uz: 'Arizani qoldirish ',
        lt: ''
    },
    payAssistantIs: {
        ru: 'Pay Assistant — это:',
        en: 'Pay Assistant:',
        uz: 'Pay Assistant — bu:',
        lt: ''
    },
    payAssistantIsSafe: {
        ru: 'Безопасная работа',
        en: 'Safe',
        uz: 'Xavfsizlik',
        lt: ''
    },
    payAssistantIsSafeText: {
        ru: 'Функция безопасной сделки. Передача прав интеллектуальной собственности. Стандарт защиты PCI DSS',
        en: 'Minimise civil and tax risks when working with freelancers and ensure safe transactions',
        uz: 'Frilanserlar bilan ishlashda barcha qonuniy va soliq xavf-xatarlarini yopib qo’ying, “xavfsiz bitimlar” tuzing ',
        lt: ''
    },
    payAssistantIsSimple: {
        ru: 'Удобный документооборот',
        en: 'Simple',
        uz: 'Oddiylik',
        lt: ''
    },
    payAssistantIsSimpleText: {
        ru: 'Вся платежная информация по внештатным сотрудникам хранится в одном месте. Сервис интегрирован с Банком 131 и приложением “Мой налог”',
        en: 'Cooperate with professionals under a single contract',
        uz: 'Barcha pudratchilar – ijrochilar uchun bitta shartnoma tuzing',
        lt: ''
    },
    payAssistantIsConvenient: {
        ru: 'Выплаты исполнителям',
        en: 'Convenient',
        uz: 'Qulaylik',
        lt: ''
    },
    payAssistantIsConvenientText: {
        ru: 'Мгновенные выплаты на карты Visa/MasterCard/МИР, платежные системы Qiwi/Криптовалюта (USDT) в один клик по всему миру',
        en: 'Receive documentation for each project in one package and quickly make any number of payments with one click',
        uz: 'Har bir loyiha bo’yicha hujjatlarni bitta paketda oling va bir marta klik bosish orqali har qanday miqdordagi toʻlovlarni tezda amalga oshiring',
        lt: '',
    },
    payAssistantIsModernized: {
        ru: 'Простой функционал',
        en: 'Modernized',
        uz: 'Optimallash',
        lt: ''
    },
    payAssistantIsModernizedText: {
        ru: 'Постановка задач и их контроль. Выгрузка отчетов и автоматизированная работа с актами',
        en: 'Streamline vendor payments to contractors directly from your system through simple API integration',
        uz: 'API bo’yicha oddiy integratsiya orqali to\'g\'ridan-to\'g\'ri o’z tizimingiz orqali ijrochilarga to\'lovlarni amalga oshiring ',
        lt: ''
    },
    whoNeedsPayAssistant: {
        ru: 'Кому мы полезны?',
        en: 'Who needs Pay Assistant',
        uz: 'Pay Assistant  kimga  kerak',
        lt: ''
    },
    whoNeedsPayAssistantCEO: {
        ru: 'Владельцам бизнеса и руководителям компаний\n',
        en: 'CEOs and Company Executives',
        uz: 'Biznes  egalari va kompaniya rahbarlariga',
        lt: ''
    },
    whoNeedsPayAssistantCEOText: {
        ru: 'Сервис массовых выплат для юр лиц позволяет оптимизировать работу с любыми типами внештатников, самозанятыми, ИП, физлицами',
        en: 'Streamline task management and cut down expenses in order to open up new business opportunities',
        uz: 'Biznesni rivojlantirish uchun yangi imkoniyatlarni ochish uchun jarayonlarni optimallashtiring va xarajatlarni qisqartiring',
        lt: ''
    },
    whoNeedsPayAssistantAccountants: {
        ru: 'Финансовому директору и бухгалтерам',
        en: 'Financial Directors and Accountants',
        uz: 'Moliyaviy direktor va buxgalterlarga ',
        lt: ''
    },
    whoNeedsPayAssistantAccountantsText: {
        ru: 'Автоматизируйте выплаты исполнителям безопасно и быстро',
        en: 'Optimise your time management when working with documents and streamline vendor payments',
        uz: 'Hujjatlar bilan ishlash va frilanserlarga to\'lovlarni amalga oshirish uchun vaqtni tejang ',
        lt: ''
    },
    whoNeedsPayAssistantPM: {
        ru: 'Менеджерам проектов и руководителям отделов',
        en: 'Project Managers and Team Leaders',
        uz: 'Loyiha boshqaruvchilari va bo’lim rahbarlariga ',
        lt: ''
    },
    whoNeedsPayAssistantPMText: {
        ru: 'Управление всеми исполнителями в личном кабинете: постановка задач, проверка и выплаты. Массовые платежи в один клик через список в excel',
        en: 'Ditch the routine — draw up documents and make payments with one click',
        uz: 'Qoloqlikdan xalos bo\'ling – hujjatlarni rasmiylashtiring va bir marta klik bosish orqali to\'lovlarni amalga oshiring ',
        lt: ''
    },
    processesTitle: {
        ru: 'Отрасли которым мы нужны',
        en: 'Customising processes for different industries',
        uz: 'Biz turli sohalar uchun jarayonlarni o\'rnatdik ',
        lt: ''
    },
    CPA: {
        ru: 'CPA - сети',
        en: 'CPA networks',
        uz: 'СPA – tarmoqlar',
        lt: ''
    },
    IT: {
        ru: 'IT-разработка',
        en: 'IT development',
        uz: 'IT ishlab chiqish ',
        lt: ''
    },
    digital: {
        ru: 'Digital',
        en: 'Digital',
        uz: 'Digital',
        lt: ''
    },
    media: {
        ru: 'СМИ',
        en: 'Media',
        uz: 'OAV – Ommaviy axborot vositalari ',
        lt: ''
    },
    logistics: {
        ru: 'Логистика',
        en: 'Logistics',
        uz: 'Logistika',
        lt: ''
    },
    onlineEducation: {
        ru: 'Онлайн образование',
        en: 'Online education',
        uz: 'Onlayn-ta’lim ',
        lt: ''
    },
    publishingHouses: {
        ru: 'Издательские дома',
        en: 'Publishing houses',
        uz: 'Nashriyot uylari',
        lt: ''
    },
    callCenters: {
        ru: 'Колл-центры',
        en: 'Call centres',
        uz: 'Koll-markazlar',
        lt: ''
    },
    replenishment: {
        ru: 'Пополнение',
        en: 'Replenishment',
        uz: 'To\'ldirish',
        lt: ''
    }
};


export default mainPage;