import Image from 'next/image';
import styled, { css } from 'styled-components';

import mainPage from '@logic/language/ex/landing/index/mainPage';
import labels from '@logic/language/ex/landing/labels/labels';
import useLanguage from '@hooks/useLanguage';

import {useAppSelector} from '@store/hooks/hooks';

import { Title2 } from '@components/common/texts/typography/heading';
import { Text } from '@components/common/texts/typography/Typography';
import { Breakpoints } from '@components/styledComponents/base/breakpoints';
import { Colors } from '@components/styledComponents/base/Colors';
import { mediaBreakpointDown } from '@components/styledComponents/base/functions';

export default function Processes () {
    const texts = useLanguage(mainPage);
    const {language} = useAppSelector(state => state.generalStatesSlice);

    const blocks = [
        {
            image: '/landing/main/processes/CPA.svg',
            text: texts.CPA[language]
        },
        {image: '/landing/main/processes/Screen.svg', text: texts.IT[language]},
        {image: 'landing/main/processes/Earth.svg', text: texts.digital[language]},
        {image: 'landing/main/processes/News.svg', text: texts.media[language]},
        {image: 'landing/main/processes/Box.svg', text: texts.logistics[language]},
        {image: 'landing/main/processes/AcademicCap.svg', text: texts.onlineEducation[language]},
        {image: 'landing/main/processes/Book.svg', text: texts.publishingHouses[language]},
        {image: 'landing/main/processes/Headset.svg', text: texts.callCenters[language]},
    ];

    return (
        <Container>
            <Content>
                <Title2 styles={css`text-align: center;`}>
                    {texts.processesTitle[language]}
                </Title2>
                <Blocks>
                    {
                        blocks.map((block, index) => (
                            <BlockWhite key={index}>
                                <BlockBorder>
                                    <Image 
                                        width={80}
                                        height={80}
                                        alt={labels.imgAlt[language]} 
                                        src={block.image}/>
                                </BlockBorder>
                                <Text font='body' as='p'>
                                    {block.text}
                                </Text>
                            </BlockWhite>
                        ))
                    }
                </Blocks>
            </Content>
        </Container>
    );
}


const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 120px;
  padding-top: 60px;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  display: grid;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  gap: 32px;
  padding: 0 20px;

  ${mediaBreakpointDown(Breakpoints.xMobile)} {
    gap: 24px;
  }
`;

const Blocks = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 220px);
  grid-template-rows: repeat(2, 200px);
  gap: 20px;

  @media (max-width: 1250px) {
    grid-template-columns: repeat(4, 236px);
    grid-template-rows: repeat(2, max-content);
  }
  @media (max-width: 1100px) {
    grid-template-columns: repeat(4, 200px);
    grid-template-rows: repeat(2, max-content);
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(4, 157px);
    grid-template-rows: repeat(2, max-content);
  }
  @media (max-width: 700px) {
    grid-template-columns: repeat(2, 157px);
    gap: 20px 0;
    grid-template-rows: repeat(4, max-content);
  }
`;

const BlockWhite = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @media (max-width: 900px) {
    height: 100%;
    width: 157px;
  }
  
`;

const BlockBorder = styled.div`
  width: 164px;
  height: 164px;
  border: 1px solid ${Colors.EMERALD};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    height: 124px;
    width: 124px;
    border-radius: 40px;
  }

  img {
    height: 80px;
    width: 80px;
    object-fit: contain;

    @media (max-width: 700px) {
      height: 64px;
      width: 64px;
    }
  }
`;