import React from 'react';
import styled from 'styled-components';

import SiteType from '@logic/enums/TypeSite';
import FAQ_OLD from '@logic/language/ex/landing/index/FAQ';
import FAQ_NEW from '@logic/language/ex/landing/index/FAQ_NEW';
import useLanguage from '@hooks/useLanguage';

import { useAppSelector } from '@store/hooks/hooks';

import FAQPanel from '@components/landing/components/FAQPanel/FAQPanel';

export default function FAQ () {

    const texts = useLanguage(FAQ_NEW);
    const {site} = useAppSelector(state => state.generalStatesSlice);

    const faqs = [
        {
            title: site === SiteType.RU ? FAQ_OLD.question1 : texts.question1,
            answer: site === SiteType.RU ? FAQ_OLD.answer1 : texts.answer1
        },
        {
            title: site === SiteType.RU ? FAQ_OLD.question2 : texts.question2,
            answer: site === SiteType.RU ? FAQ_OLD.answer2 : texts.answer2
        },
        {
            title: site === SiteType.RU ? FAQ_OLD.question3 : texts.question3,
            answer: site === SiteType.RU ? FAQ_OLD.answer3 : texts.answer3
        },
        {
            title: site === SiteType.RU ? FAQ_OLD.question4 : texts.question4,
            answer: site === SiteType.RU ? FAQ_OLD.answer4 : texts.answer4
        },
        {
            title: site === SiteType.RU ? FAQ_OLD.question5 : texts.question5,
            answer: site === SiteType.RU ? FAQ_OLD.answer5 : texts.answer5
        },
    ];

    return (
        <Container>
            <FAQPanel 
                faqs={faqs}   
                title={texts.title} 
                button={texts.askQuestion}    
                applicationTitle={texts.applicationTitle}    
            />
        </Container>
    );
}

const Container = styled.div`
  width: 100%;
  padding: 120px 0;
  display: flex;
  justify-content: center;
`;