import styled from 'styled-components';

import {useAppSelector} from '@store/hooks/hooks';

import {Colors} from '@components/styledComponents/base/Colors';
import { typography } from '@components/styledComponents/base/typography';

import PlusIcon from '@public/icons/baseUI/cross-icons/plus.svg';

export default function Charts () {

    const {language} = useAppSelector(state => state.generalStatesSlice);

    const activeProjects = {
        ru: 'Активных проектов',
        en: 'Active projects',
        lt: 'Aktyvių projektų',
        uz: 'Faol vazifalar',
    };

    const freelancers = {
        ru: 'Исполнителей',
        en: 'Freelancers',
        lt: 'Atlikėjai',
        uz: 'Ishchilar',
    };
    const paidTasks = {
        ru: 'Оплаченных задач',
        en: 'Paid tasks',
        lt: 'Apmokėtų užduočių',
        uz: 'To`langan vazifalar',
    };

    const replimishment = {
        ru: 'Пополнения',
        en: 'Top up',
        lt: 'Papildymai',
        uz: 'To`ldirish',
    };

    const withdraw = {
        ru: 'Выводы',
        en: 'Withdrawals',
        lt: 'Išėmimai',
        uz: 'Chiqarish',
    };

    return (
        <Container>
            <Blocks>
                <Block>
                    <Num>29</Num>
                    <Plus><PlusIcon/></Plus>
                    <ChartParagraph>
                        {activeProjects[language]}
                    </ChartParagraph>
                </Block>
                <Block>
                    <Num>22</Num>
                    <Plus><PlusIcon/></Plus>
                    <ChartParagraph>
                        {freelancers[language]}
                    </ChartParagraph>
                </Block>
                <Block>
                    <Num>45</Num>
                    <ChartParagraph>
                        {paidTasks[language]}
                    </ChartParagraph>
                </Block>
            </Blocks>
            <ChartBlock>
                <LineBlock>
                    <VerticalLine>
                        <NumsParagraph>40K</NumsParagraph>
                        <NumsParagraph>30K</NumsParagraph>
                        <NumsParagraph>20K</NumsParagraph>
                        <NumsParagraph>10K</NumsParagraph>
                        <NumsParagraph>0K</NumsParagraph>
                    </VerticalLine>
                    {/*<GreenLine/>*/}
                    <LinesBlock>
                        <svg style={{position: 'absolute'}} width="100%" height="90%" viewBox="0 0 230 135" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path id={'path1'} d="M1.17773 34.4687C10.2398 34.4687 15.0094 133.755 29.5565 133.755C44.1035 133.755 42.9112 0.918457 59.1276 0.918457C75.344 0.918457 66.9973 68.0189 84.8831 68.0189C102.769 68.0189 99.6686 133.755 114.216 133.755C128.763 133.755 155.711 133.755 172.404 133.755C189.097 133.755 182.42 20.0121 197.683 20.0121C212.945 20.0121 201.737 52.1985 229.4 52.1985" stroke="#3F3CE4" strokeWidth="1.17037" strokeLinecap="round"/>
                            <path id={'path2'} d="M1.17773 89.888C1.17773 77.9821 16.859 80.5798 27.2327 80.5798C37.6064 80.5798 46.2914 65.4268 56.4239 65.4268C66.5564 65.4268 71.1401 75.6009 85.1326 75.6009C99.1251 75.6009 102.503 0.918457 117.701 0.918457C132.9 0.918457 133.865 54.1703 144.239 54.1703C154.613 54.1703 157.508 65.4268 170.776 65.4268C184.045 65.4268 189.835 38.8009 200.45 38.8009C208.942 38.8009 223.288 84.5484 229.4 107.422" stroke="#9DECF1" strokeWidth="1.17037" strokeLinecap="round"/>
                        </svg>
                    </LinesBlock>
                    <HorizontalLine>
                        <NumsParagraph>01.20</NumsParagraph>
                        <NumsParagraph>02.20</NumsParagraph>
                        <NumsParagraph>03.20</NumsParagraph>
                        <NumsParagraph>04.20</NumsParagraph>
                        <NumsParagraph>05.20</NumsParagraph>
                        <NumsParagraph>06.20</NumsParagraph>
                    </HorizontalLine>
                </LineBlock>
                <Sticks>
                    <LineData>
                        <Stick color={Colors.NEW}/>
                        <ChartParagraph>{withdraw[language]}</ChartParagraph>
                    </LineData>
                    <LineData>
                        <Stick color={Colors.NEW}/>
                        <ChartParagraph>{replimishment[language]}</ChartParagraph>
                    </LineData>
                </Sticks>
            </ChartBlock>

        </Container>
    );

}


const Container = styled.div`
  height: 320px;
  max-width: 316px;
  background: #FFFFFF;
  box-shadow: 4px 4px 44px rgba(0, 0, 0, 0.05), 4px 4px 15px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  
  padding: 23px;
  @media (max-width: 1200px) {
    padding: 23px;
  }
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-self: center;
  width: 100%;
`;

const Blocks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
`;

const Block = styled.div`
  padding: 10px;

  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 1fr 1fr;


  gap: 3px;
  background: #FFFFFF;
  box-shadow: 0px 2.34074px 8.77778px rgba(0, 0, 0, 0.1);
  border-radius: 5.85185px;
`;

const Num = styled.div`
  ${typography.default};
  font-size: 20px;
  line-height: 100%;

  @media (max-width: 540px) {
    font-size: 14px;
  }
  
`;

const ChartParagraph = styled.span`
  ${typography.default};
  font-size: 10px;
  line-height: 130%;
  color: ${Colors.GRAY_600};

  grid-area: 2 / 1 / 3 / 3;
  align-self: center;

  @media (max-width: 540px) {
    font-size: 8px;
  }
`;

const NumsParagraph = styled.span`
  ${typography.default};
  font-size: 8px;
  line-height: 130%;
  color: ${Colors.GRAY_600};
  align-self: center;
`;

const Plus = styled.div`
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
  font-size: 0;
  min-width: unset;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: ${Colors.GRAY_800};
  border-radius: 50%;

  svg {
    flex-shrink: 0;
    width: 10px;
    height: 10px;
    fill: ${Colors.WHITE};
  }

  @media (max-width: 540px) {
    width: 10px;
    height: 10px;

    svg {
      width: 5px;
      height: 5px;
    }
  }
`;

const ChartBlock = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 2.34074px 8.77778px rgba(0, 0, 0, 0.1);
  border-radius: 5.85185px;
  
  padding: 8px;
  width: 100%;
  height: 220px;
  
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const LineBlock = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  
`;

const VerticalLine = styled.div`
  position: absolute;
  width: 16px;
  top: 0;
  left: 0;
  bottom: 16px;
  border-right:  0.585185px solid ${Colors.GRAY_400};
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const HorizontalLine = styled.div`
  position: absolute;
  height: 16px;
  bottom: 0;
  left: 16px;
  right: 0;
  
  border-top: 0.585185px solid ${Colors.GRAY_400};

  display: flex;
  justify-content: space-between;
`;

const LinesBlock = styled.div`
  position: absolute;
  top: 0;
  left: 16px;
  bottom: 16px;
  right: 0;
  margin: auto;

  padding-top: 10px;

  #path2 {
    transition: 0.5s;
    cursor: pointer;

    &:hover {
      stroke: #3CDAE4;
    }
  }

  #path1 {
    transition: 0.5s;
    cursor: pointer;

    &:hover {
      stroke: #2f2bde;
    }
  }

`;

const Sticks = styled.div`
  padding: 0 16px;
  display: flex;
  gap: 20px;
`;

const LineData = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
const Stick = styled.div<{color: Colors}>`
  width: 11.85px;
  height: 1.9px;
  background: ${({color}) => color};
  border-radius: 4.74074px;
`;