import React, {useContext} from 'react';
import Image from 'next/image';
import styled, { css } from 'styled-components';

import SiteType from '@logic/enums/TypeSite';
import mainPage from '@logic/language/ex/landing/index/mainPage';
import labels from '@logic/language/ex/landing/labels/labels';
import {LandingContext} from '@context/LandingContext';
import useLanguage from '@hooks/useLanguage';

import {useAppSelector} from '@store/hooks/hooks';

import Button from '@components/common/buttons/Button';
import { ButtonStyle } from '@components/common/buttons/buttonStyles';
import { Heading2 } from '@components/common/texts/typography/heading';
import { Text } from '@components/common/texts/typography/Typography';
import {Colors} from '@components/styledComponents/base/Colors';
import { vw } from '@components/styledComponents/base/functions';
import { CurrencySymbols } from '@components/styledComponents/base/vars';

import OptimisationIcon from '@public/landing/main/advantages/optimisation.svg';
import SafeIcon from '@public/landing/main/advantages/safe.svg';
import SimpleIcon from '@public/landing/main/advantages/simple.svg';
import UsefulIcon from '@public/landing/main/advantages/useful.svg';
import freel from '@public/landing/main/banner/freelancer2.png';

import Header from '@landing/components/NewHeader/Header';

import { Badge } from './Badge';

export default function MainBanner () {

    const {setApplicationFormOptions} = useContext(LandingContext);

    const {language, site} = useAppSelector(state => state.generalStatesSlice);

    const texts = useLanguage(mainPage);

    const advantages = [
        {
            Icon: SafeIcon,
            title: texts.payAssistantIsSafe,
            text: texts.payAssistantIsSafeText
        },
        {
            Icon: SimpleIcon,
            title: texts.payAssistantIsSimple,
            text: texts.payAssistantIsSimpleText
        },
        {
            Icon: UsefulIcon,
            title: texts.payAssistantIsConvenient,
            text: texts.payAssistantIsConvenientText
        },
        {
            Icon: OptimisationIcon,
            title: texts.payAssistantIsModernized,
            text: texts.payAssistantIsModernizedText
        },
    ];

    return (
        <Container>
            <Content>
                <Banner>
                    <Header/>
                    <BannerContent>
                        <BannerText>
                            <BannerTextSide>
                                <Text font='title1' color={Colors.WHITE} >
                                    {texts.mainTitle[language]}
                                </Text>
                                <Text font='body' as='p' color={Colors.WHITE}>
                                    {texts.mainDescription[language]}
                                </Text>
                            </BannerTextSide>
                            <Button
                                data-test-id='indexBannerApplicationButton'
                                wideOnBreakPoint={562}
                                styleType={ButtonStyle.GREEN} 
                                onClick={()=>setApplicationFormOptions({isOpen: true, title: texts.applicationTitle})}>
                                {texts.applicationButton[language]}
                            </Button>
                        </BannerText>
                        <BadgeWrapper>
                            <Badge 
                                styles={badgeStyleOne}
                                time='12:19' 
                                text={`${texts.replenishment[language]}: 66 000 ${site === SiteType.RU ? CurrencySymbols.rub : CurrencySymbols.usd}`}/>
                            <Badge 
                                styles={badgeStyleTwo}
                                time='10:33' 
                                text={`${texts.replenishment[language]}: 19 650 ${site === SiteType.RU ? CurrencySymbols.rub : CurrencySymbols.eur}`}/>
                            <Badge 
                                styles={badgeStyleThree}
                                time='15:45' 
                                text={`${texts.replenishment[language]}: 37 400 ${site === SiteType.RU ? CurrencySymbols.rub : CurrencySymbols.usd}`}/>
                        </BadgeWrapper>
                        
                        <Tablet>
                            <Image
                                fill
                                src={freel}
                                alt={labels.imgAlt[language]} />
                        </Tablet>
                    </BannerContent>

                    <ImageWrapper>
                        {/*<Image fill src={bannerImage} alt={labels.imgAlt[language]} />*/}
                    </ImageWrapper>
                </Banner>
                <Advantages>
                    <AdvantagesContent>
                        {
                            advantages.map((advantage, index) => (
                                <Advantage key={index}>
                                    <advantage.Icon/>
                                    <TextContainer>
                                        <Heading2 as='h3' color={Colors.GRAY_900}>
                                            {advantage.title[language]}
                                        </Heading2>
                                        <Text font='body' as='p' color={Colors.GRAY_900}>
                                            {advantage.text[language]}
                                        </Text>
                                    </TextContainer>
                                </Advantage>
                            ))
                        }
                    </AdvantagesContent>
                </Advantages>
            </Content>
        </Container>
    );
}


const Container = styled.div`
  width: 100%;
  background: linear-gradient(0deg, #EEF7FA 0%, rgba(238, 247, 250, 0) 100%);
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  position: absolute;
  z-index: 0;
  inset: 0;
  background: url("/backgrounds/main_banner_new.svg") no-repeat center bottom;
  background-size: cover;

  &:before {
    position: absolute;
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    //background-color: lightgray;
    mix-blend-mode: multiply;
    background-image: url('/noice/noice.svg');
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  @media screen and (max-width: 750px) {
    padding: 0;
  }
`;

const Banner = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 0 24px;
  position: relative;
  min-height: 550px;
  height: 95vh;
  max-height: 730px;
  box-shadow: 0px 10px 64px rgba(0, 0, 0, 0.11);
  border-radius: 0px 0px 30px 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media screen and  (max-width: 1200px) {
    height: 560px;
  }

  @media  screen and (max-width: 768px) {
    height: 760px;
  }

  @media  screen and (max-width: 561px) {
    height: 700px;
  }

  @media  screen and (max-width: 390px) {
    height: 740px;
  }
`;

const BannerContent = styled.div`
  position: relative;
  z-index: 2;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  
  @media  screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Advantages = styled.div`
  padding: 100px 24px 120px 24px;
  display: flex;
  justify-content: center;
  
  @media  screen and (max-width: 1200px) {
    padding: 43px 24px;
  }
`;

const AdvantagesContent = styled.div`
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  grid-gap: 67px;
  
  @media  screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
    //grid-template-rows: 150px 150px;
  }
  @media  screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

const Advantage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const BannerText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;

  @media  screen and (max-width: 900px) {
    max-width: 370px
  }
  
  @media  screen and (max-width: 768px) {
    margin-top: 24px;
    align-items: flex-start;
    max-width: 100%;
    gap: 24px;
  }
`;

const BannerTextSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 450px;

  @media  screen and (max-width: 1200px) {
    p:last-child {
      max-width: 300px;
    }
  }
`;

const Tablet = styled.div`
  width: 100%;
  max-width: 525px;
  position: absolute;
  right: 0;
  bottom: -5%;
  aspect-ratio: 1595/1906;

  img {
    object-fit: contain;
  }
  
  @media  screen and (max-width: 1200px) {
    max-width: 420px;
  }

  @media  screen and (max-width: 1024px) {
    max-width: 340px; 
    right: -${vw(50, 900)};
  }

  @media  screen and (max-width: 768px) {
    max-width: 330px; 
    right: 0;
  }

  @media  screen and (max-width: 561px) {
    max-width: 270px; 
    left: 20%;
    margin: auto;
  }
`;

const BadgeWrapper = styled(Tablet)`
`;

const badgeStyleOne = css`
  position: absolute;
  z-index: 20 !important;
  left: -30%;
  bottom: 30%;
`;

const badgeStyleTwo = css`
  position: absolute;
  z-index: 0;
  left: -5%;
  top: 20%;
`;

const badgeStyleThree = css`
  position: absolute;
  z-index: 0;
  right: 2%;
  bottom: 51%;
`;