import React, { FormEvent, useState } from 'react';
import Image from 'next/image';
import Link from "next/link";
import styled, { css } from 'styled-components';

import ValidatorResult from '@logic/forms/validators/result/ValidatorResult';
import ApplicationFormValidator from '@logic/forms/validators/validators/ApplicationFormValidator';
import MetricHelper from '@logic/helpers/MetricHelper';
import labels from '@logic/language/ex/landing/labels/labels';
import { AppealFormDataClient } from '@logic/models/FormData/FormData';
import { ApiErrorAdapter } from '@logic/service/API/error/errorsAdapter';
import { PayAssistantError } from '@logic/service/API/error/PayAssistantError';
import AppealRequest from '@logic/service/ApiRequest/RequestTypes/AppealRequest/AppealRequest';
import ApiService from '@logic/service/ApiService/ApiService';
import useDataStateUpdater from '@hooks/useDataStateUpdater';
import useLanguage, { Texts } from '@hooks/useLanguage';
import { usePaApiService } from "@hooks/usePaApiService";

import { useAppSelector } from '@store/hooks/hooks';

import Button from '@components/common/buttons/Button';
import { ButtonStyle } from '@components/common/buttons/buttonStyles';
import TextArea from '@components/common/inputs/TextArea/TextArea';
import { Title1 } from '@components/common/texts/typography/heading';
import { Text } from '@components/common/texts/typography/Typography';
import { Breakpoints } from '@components/styledComponents/base/breakpoints';
import { Colors } from '@components/styledComponents/base/Colors';
import { mediaBreakpointDown, vw } from '@components/styledComponents/base/functions';
import { typography } from '@components/styledComponents/base/typography';
import AnyError from '@src/common/errors/AnyError/AnyError';
import PhoneInputWidthEntryError from '@src/common/inputs/PhoneInput/PhoneInputWidthEntryError';
import SimpleInputWidthEntryError from '@src/common/inputs/SImpleInput/SimpleInputWidthEntryError';
import Spinner from '@src/common/loaders/Spinner/Spinner';
import ModalWindow from '@src/common/modals/ModalWindoiw/ModalWindow';

import phoneImage from '@public/landing/main/phone.png';

import AppealSuccess from '@landing/components/ApplicationForm/AppealSuccess';

const formInitData: AppealFormDataClient = {
    name: ``,
    phone: {
        number: '',
        country: ''
    },
    email: ``,
    question: ``
};

interface FormAppealProps {
    textData: Texts;
}

export default function FormAppeal ({textData}: FormAppealProps) {

    const { site, language } = useAppSelector((state) => state.generalStatesSlice);
    const texts = useLanguage(textData);

    const validator = new ApplicationFormValidator();
    const [validatorResult, setValidatorResult] = useState(new ValidatorResult<AppealFormDataClient>());
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<PayAssistantError | null>(null);
    const apiService = usePaApiService(ApiService);

    const [data, setData] = useDataStateUpdater<AppealFormDataClient>(formInitData);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const removeError = () => {
        setErrors(null);
        setValidatorResult(new ValidatorResult<AppealFormDataClient>());
    };
    const sendForm = (e?: FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        const formValidatorResult = validator.validate(data);
        setValidatorResult(formValidatorResult);
        if (!formValidatorResult.isSuccess()) {
            return;
        }

        const body: AppealRequest = {
            question: data.question,
            name: data.name,
            phone: data.phone.number,
            siteVersion: process.env.NEXT_PUBLIC_SITE as string
        };
        setIsLoading(true);

        MetricHelper.sendComagicApplication({
            name: data.name,
            phone: data.phone.number,
            message: data.question
        });

        apiService.sendAppealForm(body).then((response) => {
            if (response.isSuccess()) {
                setIsSuccess(true);
                setErrors(null);
            } else {
                setErrors(ApiErrorAdapter.getPayAssistantError(response.getError()));
            }
        }).finally(() => {
            setIsLoading(false);
        });

    };

    const getPersonalDataLink = () => {

        const text = texts.personalDataText[language] as string;
        const textBefore = text.substring(0, text.indexOf('{}'));
        const textAfter = text.substring(text.indexOf('{}') + 2);

        // TODO PA-1790: return to this after adding privacy policy
        return (
            <Text font='body' color={Colors.WHITE} as='p'>
                {textBefore}
                <Link href={'/'} target={'_blank'}>
                    <DocsLink>
                        {texts.personalDataLink[language]}
                    </DocsLink>
                </Link>
                {textAfter}                  
            </Text>
        );
    };

    return (
        <Container>

            <BackgroundImage/>

            <Content>
                <Form onSubmit={sendForm}>
                    <Top>
                        <Title1 as='h2' color={Colors.WHITE} >
                            {texts.title[language]}
                        </Title1>
                        <Text font='body' color={Colors.WHITE} as='p'>
                            {texts.underTitle[language]}
                        </Text>
                    </Top>
                    <Fields>
                        <PhoneInputWidthEntryError
                            removeError={removeError}
                            value={data.phone}
                            name={'phone'}
                            placeholder={texts.phone[language] as string}
                            onChange={(value) => {
                                setData('phone', value);
                            }}
                            validatorResult={validatorResult}
                            error={errors}
                        />
                        <SimpleInputWidthEntryError
                            removeError={removeError}
                            placeholder={texts.name[language] as string}
                            fieldName={'name'}
                            onChange={(value) => setData('name', value)}
                            validatorResult={validatorResult}
                            error={errors}
                            value={data.name}
                        />
                        {/* <SimpleInput fieldName={'email'} value={''} onChange={()=>{}}/>*/}
                        <TextArea
                            styles={textArea}
                            placeholder={texts.question[language] as string} value={data.question}
                            onChange={(e) => setData('question', e.target.value)}/>
                        <LoadingContainer isLoading={isLoading}>
                            <Spinner size={30} loading={isLoading} />
                        </LoadingContainer>
                    </Fields>
                    <Bottom>
                        <Button 
                            data-test-id='applicationFormSubmitButton'
                            styleType={ButtonStyle.GREEN} 
                            isLoading={isLoading}
                            disabled={isLoading}>
                            {texts.button[language]}
                        </Button>
                        
                        {
                            errors?.getAnyErrorExcept(['name', 'question', 'phone']) !== null &&
                            <AnyError error={errors} />
                        }
                        {!errors?.getAnyErrorExcept(['name', 'question', 'phone']) && (
                            <PersonalData>
                                {getPersonalDataLink()}
                            </PersonalData>)}

                    </Bottom>
                </Form>
                <PhoneContainer>
                    <PhoneImage 
                        src={phoneImage} 
                        alt={labels.imgAlt[language]} />
                </PhoneContainer>
            </Content>
            <ModalWindow isOpen={isSuccess} onClose={() => setIsSuccess(false)}>
                <ModalContainer onSubmit={(e) => e.preventDefault()}>
                    <AppealSuccess onClose={() => setIsSuccess(false)} />
                </ModalContainer>
            </ModalWindow>
        </Container>
    );
}

const ModalContainer = styled.div`
  position: relative;
  padding: 48px;
  background: #FFFFFF;
  border-radius: 20px;
  width: 100%;
  max-width: 456px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 400px) {
    padding: 24px;
    border-radius: 10px;
  }
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BackgroundImage = styled.div`
  position: absolute;
  z-index: 0;
  inset: 0;
  background: url("/backgrounds/banner_2.svg") no-repeat center center;
  background-size: cover;

  &:before {
    position: absolute;
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    mix-blend-mode: multiply;
    background-image: url('/noice/noice.svg');
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 1200px;
  display: flex;
  padding:  0 20px;
`;

const Form = styled.form`
  padding: 100px 0 125px 0;
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${mediaBreakpointDown(Breakpoints.xMedium)} {
      padding: 75px 0 75px 0;
  }

  ${mediaBreakpointDown(900)} {
      padding: 54px 0 54px 0;
      width: 58vw;
  }

  ${mediaBreakpointDown(Breakpoints.Tablet)} {
      padding: 35px 0 35px 0;
      width: 100%;
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${mediaBreakpointDown(Breakpoints.xMobile)} {
    gap: 8px;
  }
`;

const Fields = styled.div`
  position: relative;
    display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 16px;
  @media (max-width: 680px) {
    grid-template-columns: 1fr;
  }
`;

const textArea = css`
  height: 108px;
  grid-area: 2 / 1 / 2 / 3;

  @media (max-width: 680px) {
    grid-area: unset;
  }
`;

const Bottom = styled.div`
  display: flex;
  height: 57px;
  align-items: center;
  gap: 16px;

  ${mediaBreakpointDown(Breakpoints.xTablet)} {
    height: unset;
    gap: 24px;
    flex-direction: column;
    align-items: flex-start;
  }

  ${mediaBreakpointDown(Breakpoints.xMobile)} {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
`;

const PersonalData = styled.span`
  ${typography.body};
  font-size: 14px;
  line-height: 140%;
  color: ${Colors.WHITE};
  max-width: 336px;

    ${mediaBreakpointDown(Breakpoints.xMobile)} {
    text-align: center;
  }
`;

const PhoneContainer = styled.div`
  position: relative;
  left: 0;
  right: 0;
  top: 20px;
  bottom: 0;
  width: calc(100% - 600px);

  ${mediaBreakpointDown(Breakpoints.xMedium)} {
    width: calc(100% - 585px);
  }

  ${mediaBreakpointDown(Breakpoints.Tablet)} {
      display: none;
  }
`;

const PhoneImage = styled(Image)`
  position: absolute;
  width: 440px;
  height: 785px;
  z-index: 0;
  right: 0;

  ${mediaBreakpointDown(Breakpoints.Large)} {
      width: ${vw(450, Breakpoints.Large)};
      height:  ${vw(785, Breakpoints.Large)};
  }

  ${mediaBreakpointDown(Breakpoints.Medium)} {
      width: ${vw(341, Breakpoints.Medium)};
      height:  ${vw(608, Breakpoints.Medium)};
      transform: rotate(-10deg);
      right: -100px;
  }

  ${mediaBreakpointDown(860)} {
      right: -150px;
      transform: rotate(-12deg);
      width: 293px;
      height:  500px;
  }
`;


const DocsLink = styled.span`
  border-bottom: 1px solid white;
  cursor: pointer;
`;

const LoadingContainer = styled.div<{ isLoading: boolean }>`
  position: absolute;
  left: -10px;
  top: -10px;
  bottom: -10px;
  right: -10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .5s;
  opacity: ${({ isLoading }) => isLoading ? 1 : 0};
  visibility: ${({ isLoading }) => isLoading ? 'visible' : 'hidden'};
  pointer-events: ${({ isLoading }) => isLoading ? 'all' : 'none'};
  backdrop-filter: blur(3px);
`;