import TypeSite from '@logic/enums/TypeSite';
import applicationForm from '@logic/language/ex/landing/index/applicationForm';

import {useAppSelector} from '@store/hooks/hooks';

import FormAppeal from '@components/landing/components/FormAppeal/FormAppeal';
import Processes from '@components/landing/pages/index/Processes';
import LandingLayout from '@src/landing/layouts/LandingLayout/LandingLayout';

import Footer from '@landing/components/Footer/Footer';
import FAQ from '@landing/pages/faq/FAQNew';
import ForWho from '@landing/pages/index/ForWho';
import MainBanner from '@landing/pages/index/MainBanner';

export default function Home () {

    const {site} = useAppSelector(state => state.generalStatesSlice);

    const title = site === TypeSite.RU ?
        `Надежный помощник в работе с фрилансерами | Pay Assistant` : `Fast payments to freelancers and self-employed with Pay Assistant.`;
    const content = site === TypeSite.RU ?
        `Упрощаем документооборот и взаиморасчеты с удаленными исполнителями в рамках одного договора. Ставьте задачи, обменивайтесь файлами, платите сотрудникам в любой валюте.`
        : `We automate the process of interaction between companies and remote employees. Set tasks, exchange files, make payments in one click.`;


    return (
        <LandingLayout title={title} content={content}>
            <MainBanner/>
            <ForWho/>
            <Processes/>
            <FormAppeal textData={applicationForm}/>
            <FAQ/>
            <Footer/>
        </LandingLayout>
    );
}


